<template>
  <v-card>
    <v-card-title> Requested License </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-text-field
            outlined
            disabled
            hide-details
            label="License Requested"
            :value="getTemplateName()"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            disabled
            hide-details
            label="Application"
            :value="getApplicationName()"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            outlined
            disabled
            hide-details
            label="Amount"
            v-model="customerlicense.amount"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            outlined
            disabled
            hide-details
            label="Runtime (Months)"
            v-model="customerlicense.months"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            disabled
            label="Requesting Partner"
            :value="getCustomerName()"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            disabled
            label="For Customer"
            :value="getSubcustomerName()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-container align="left">
        <v-timeline dense clipped>
          <v-timeline-item
            v-for="(discount, index) in discounts"
            :key="index"
            fill-dot
            :color="getColour(index, discounts.length)"
            :medium="discount.important"
            :small="!discount.important"
          >
            <!-- :color="discount.important ? '#b31616' : '#43eb34'" -->
            <v-text-field
              disabled
              hide-details
              solo
              :label="discount.text"
              :style="
                discount.important ? 'font-weight:bold' : 'font-weight:normal'
              "
            >
              <template v-slot:append>
                <span
                  :style="
                    discount.important
                      ? 'font-weight:bold'
                      : 'font-weight:normal'
                  "
                >
                  {{ discount.value }}
                </span>
              </template>
            </v-text-field>
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined @click="$emit('done')">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined @click="denyLicense">Deny License</v-btn>
      <v-btn outlined @click="grantLicense">Grant License</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "requestDialog",
  props: {
    customerlicense: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      customerById: "customerById",
      subcustomerById: "subcustomerById",
      templateById: "templateById",
      applicationById: "applicationById",
      quantitydiscountsByTemplateId: "quantitydiscountsByTemplateId",
      quantitydiscountByTemplateIdAndAmount:
        "quantitydiscountByTemplateIdAndAmount",
      durationdiscountsByTemplateId: "durationdiscountsByTemplateId",
      durationdiscountsByTemplateIdAndDuration:
        "durationdiscountsByTemplateIdAndDuration",
      customerlicensesByPurchaseId: "customerlicensesByPurchaseId",
    }),
    discounts: function () {
      var discounts = [];
      var template = this.templateById(this.customerlicense.templateid);

      var originalPrice = parseFloat(
        this.customerlicense.subscription
          ? template.monthlyprice * this.customerlicense.amount ?? 1
          : template.price * this.customerlicense.amount ?? 1
      );

      // Original Price
      discounts.push({
        text: "{0} x {1}".format(template.name, this.customerlicense.amount),
        discountPercentage: 0,
        discountValue: 0,
        value: this.customerlicense.subscription
          ? "{0}€/Month".format(originalPrice)
          : "{0}€".format(originalPrice),
        important: true,
      });

      // Partner discount
      var partnerdiscount = parseFloat(template.partnerdiscount).toFixed(2);
      var partnerdiscountValue = this.getPercentageOf(
        originalPrice,
        partnerdiscount
      ).toFixed(2);
      discounts.push({
        text: "Partnerdiscount - {0}%".format(partnerdiscount),
        discountPercentage: partnerdiscount,
        discountValue: partnerdiscountValue,
        value: "-{0}€".format(partnerdiscountValue),
        important: false,
      });

      // Quantity discount
      var quantityDiscount = this.quantitydiscountByTemplateIdAndAmount(
        this.customerlicense.templateid,
        this.customerlicense.amount
      );
      var quantityDiscountValue = this.getPercentageOf(
        originalPrice,
        parseFloat(quantityDiscount.discount)
      ).toFixed(2);
      discounts.push({
        text: "Quantity discount ({0} Licenses) - {1}%".format(
          quantityDiscount.amount,
          quantityDiscount.discount
        ),
        discountPercentage: parseFloat(quantityDiscount.discount),
        discountValue: quantityDiscountValue,
        value: "-{0}€".format(quantityDiscountValue),
        important: false,
      });

      // Duration discount
      if (this.customerlicense.subscription) {
        var durationDiscount = this.durationdiscountsByTemplateIdAndDuration(
          this.customerlicense.templateid,
          this.customerlicense.months
        );
        var durationDiscountValue = this.getPercentageOf(
          originalPrice,
          parseFloat(durationDiscount.discount)
        ).toFixed(2);
        discounts.push({
          text: "Duration discount ({0} Months) - {1}%".format(
            durationDiscount.duration,
            durationDiscount.discount
          ),
          discountPercentage: parseFloat(durationDiscount.discount),
          discountValue: durationDiscountValue,
          value: "-{0}€".format(durationDiscountValue),
          important: false,
        });
      }

      // Total Price after discounts
      var total = originalPrice;
      discounts.forEach((discount) => {
        total -= discount.discountValue;
      });
      discounts.push({
        text: "Total including discounts",
        discountPercentage: 0,
        discountValue: 0,
        value: this.customerlicense.subscription
          ? "{0}€/Month".format(total.toFixed(2))
          : "{0}€".format(total.toFixed(2)),
        important: true,
      });
      return discounts;
    },
  },
  data: () => ({}),
  methods: {
    denyLicense: function () {
      var affectedLicenses = this.customerlicensesByPurchaseId(
        this.customerlicense.purchaseid
      );
      affectedLicenses.forEach((license) => {
        this.deleteCustomerlicense(license);
      });
      this.$emit("done");
    },
    grantLicense: function () {
      var updateLicense = {
        purchaseid: this.customerlicense.purchaseid,
        templateid: this.customerlicense.templateid,
        months: this.customerlicense.months,
      };
      this.updateCustomerlicense(updateLicense);
      this.$emit("done");
    },
    getColour: function (modifier, max) {
      var base = "#b31616";
      var increase = 20;
      var transparencyStart = 255 - increase * max;
      var transparency = transparencyStart + increase * modifier;
      return base + transparency.toString(16);
    },
    getPercentageOf: function (total, percentage) {
      return parseFloat((parseFloat(total) / 100) * parseFloat(percentage));
    },
    ...mapActions({
      fetchApplications: "fetchApplications",
      fetchCustomers: "fetchCustomers",
      fetchSubcustomers: "fetchSubcustomers",
      fetchTemplates: "fetchTemplates",
      fetchDurationdiscounts: "fetchDurationdiscounts",
      fetchQuantitydiscounts: "fetchQuantitydiscounts",
      fetchTemplates: "fetchTemplates",
      updateCustomerlicense: "updateCustomerlicense",
      deleteCustomerlicense: "deleteCustomerlicense",
    }),
    getTemplateName: function () {
      return "Test License (Full)";
    },
    getApplicationName: function () {
      var template = this.templateById(this.customerlicense.templateid);
      var application = this.applicationById(template.applicationid);
      return application.name;
    },
    getCustomerName: function () {
      var customer = this.customerById(this.customerlicense.customerid);
      return customer.name;
    },
    getSubcustomerName: function () {
      var subcustomer = this.subcustomerById(
        this.customerlicense.subcustomerid
      );
      return subcustomer.name;
    },
    getPriceString: function (price, amount = 1) {
      var finalPrice = parseFloat(price * amount).toFixed(2);
      return this.customerlicense.subscription
        ? finalPrice + "€/Month"
        : finalPrice + "€";
    },
    getOriginalPrice: function () {
      var template = this.templateById(this.customerlicense.templateid);
      return this.customerlicense.subscription
        ? template.monthlyprice
        : template.price;
    },
  },
  mounted() {
    this.fetchApplications();
    this.fetchCustomers();
    this.fetchSubcustomers();
    this.fetchTemplates();
    this.fetchDurationdiscounts();
    this.fetchQuantitydiscounts();
    this.fetchTemplates();
  },
  beforeDestroy() {
    this.$emit("done");
  },
};
</script>

<style>
</style>