<template>
  <div>
    <v-card>
      <v-card-title>
        <menu-title
          class="ml-6 mt-6"
          title="Pending License Requests"
        ></menu-title>
      </v-card-title>
      <v-card-text>
        <v-simple-table class="ml-6">
          <template
            v-slot:default
            fixed-header
            height="800px"
          >
            <thead>
              <tr>
                <!-- <th class="text-left">Nr.</th> -->
                <th class="text-left">Requested</th>
                <th class="text-left">Partner</th>
                <th class="text-left">Customer</th>
                <th class="text-left">License</th>
                <th class="text-left">Amount</th>
                <th class="text-left">Application</th>
                <th class="text-left">Not for resale</th>
                <th class="text-left">Subscription</th>
                <th class="text-left">Purchase</th>
                <!-- <th class="text-left">Runtime</th> -->
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody v-if="ready">
              <tr
                v-for="(ul, index) in pendingUniqueCustomerlicenses"
                :key="ul.licenseid"
              >
                <!-- Nr. -->
                <!-- <td class="text-left">
                  {{ index + 1 }}
                </td> -->

                <!-- Requested -->
                <td class="text-left">
                  {{ getRequestDate(ul.requestedat) }}
                </td>

                <!-- Partner -->
                <td class="text-left">
                  {{ getCustomerName(ul.customerid, index) }}
                </td>

                <!-- Customer -->
                <td class="text-left">
                  {{ getSubcustomerName(ul.subcustomerid, index)}}
                </td>

                <!-- License -->
                <td class="text-left">
                  {{ getTemplateName(ul.templateid, index)}}
                </td>

                <!-- Amount -->
                <td class="text-left">
                  {{ ul.amount }}
                </td>

                <!-- Application -->
                <td class="text-left">
                  {{ getApplicationName(ul.templateid, index)}}
                </td>

                <!-- NFR -->
                <td class="text-left">
                  <v-icon :color="ul.nfr === true ? '#43eb34' : '#b31616'">
                    {{ ul.nfr === true ? 'mdi-check' : 'mdi-close'}}
                  </v-icon>
                </td>

                <!-- Subscription -->
                <td class="text-left">
                  <v-icon :color="ul.subscription === true ? '#43eb34' : '#b31616'">
                    {{ ul.subscription === true ? 'mdi-check' : 'mdi-close'}}
                  </v-icon>
                </td>

                <!-- Purchase -->
                <td class="text-left">
                  <v-icon :color="ul.subscription === false ? '#43eb34' : '#b31616'">
                    {{ ul.subscription === false ? 'mdi-check' : 'mdi-close'}}
                  </v-icon>
                </td>

                <!-- Runtime -->
                <!-- <td class="text-left">
                  {{ ul.subscription === true ? ul.months + ' Months' : '-' }}
                </td> -->

                <!-- Actions -->
                <td class="text-left">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="openRequest(ul)"
                      >
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                    <span>Show</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <!--
      <v-card-actions>
        <v-btn
          outlined
          @click="debug"
        >Debug</v-btn>
      </v-card-actions>
      -->

      <v-dialog
        v-model="requestDialog"
        max-width="1200"
        persistent
      >
        <request-dialog
          v-if="requestDialog"
          :customerlicense="licenseSelected"
          @done="clearParams();requestDialog=false"
        ></request-dialog>
      </v-dialog>
    </v-card>

  </div>
</template>
<script>
import menuTitle from "../../components/helpers/menu_title.vue";
import requestDialog from "../../components/requests_dialog.vue";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "Requests",
  components: {
    menuTitle,
    requestDialog,
  },
  props: {},
  computed: {
    ready: function () {
      var retval =
        this.$store.state.customers.customersFetched &&
        this.$store.state.subcustomers.subcustomersFetched &&
        this.$store.state.templates.templatesFetched &&
        this.$store.state.applications.applicationsFetched;
      return retval;
    },
    ...mapState({
      templates: (state) => state.templates.templates,
      applications: (state) => state.applications.applications,
    }),
    ...mapGetters({
      customerById: "customerById",
      subcustomerById: "subcustomerById",
      templateById: "templateById",
      applicationById: "applicationById",
      pendingCustomerlicenses: "pendingCustomerlicenses",
      pendingUniqueCustomerlicenses: "pendingUniqueCustomerlicenses",
      customerlicenseByPurchaseId: "customerlicenseByPurchaseId",
    }),
  },
  data: () => ({
    requestDialog: false,
    licenseSelected: {},
  }),
  methods: {
    ...mapActions({
      fetchCustomerlicenses: "fetchCustomerlicenses",
      fetchCustomerlicensesAsync: "fetchCustomerlicensesAsync",
      fetchTemplates: "fetchTemplates",
      fetchTemplatesAsync: "fetchTemplatesAsync",
      fetchCustomers: "fetchCustomers",
      fetchCustomersAsync: "fetchCustomersAsync",
      fetchSubcustomers: "fetchSubcustomers",
      fetchSubcustomersAsync: "fetchSubcustomersAsync",
      fetchApplications: "fetchApplications",
      fetchApplicationsAsync: "fetchApplicationsAsync",
    }),
    getRequestDate: function (requestedat) {
      console.log(requestedat);
      if (!requestedat) return "";
      var d = new Date(requestedat);
      return d.toLocaleString();
    },
    getCustomerName: function (customerid, index) {
      var customer = this.customerById(customerid);
      return customer.name;
    },
    getSubcustomerName: function (subcustomerid, index) {
      var subcustomer = this.subcustomerById(subcustomerid);
      return subcustomer.name;
    },
    getTemplateName: function (templateid, index) {
      var template = this.templateById(templateid);
      return template.name;
    },
    getApplicationName: function (templateid, index) {
      var template = this.templateById(templateid);
      var application = this.applicationById(template.applicationid);
      return application.name;
    },
    openRequest: function (ul) {
      this.licenseSelected = ul;
      this.addParams({ purchaseid: this.licenseSelected.purchaseid });
      this.requestDialog = true;
    },
    debug: function () {
      console.log("Unique Licenses:");
      console.log(this.uniqueLicenses);
      console.log(
        "Unique Licenses count: {0}".format(this.uniqueLicenses.length)
      );
      console.log("Templates:");
      console.log(this.templates);
      console.log("Applications:");
      console.log(this.applications);
    },
  },
  async mounted() {
    await this.fetchCustomersAsync();
    await this.fetchCustomerlicensesAsync();
    await this.fetchTemplatesAsync();
    await this.fetchSubcustomersAsync();
    await this.fetchApplicationsAsync();
    this.loading = false;

    var purchaseid = this.$route.query["purchaseid"];
    if (purchaseid) {
      this.licenseSelected = this.customerlicenseByPurchaseId(purchaseid);
      this.requestDialog = true;
    }
  },
};
</script>